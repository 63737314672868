export const jsonGeoFencing = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      geo_fencings: [
        {
          id: '652f42853c15fcb3eff2805d',
          name: 'Study Time',
          longitude: -122.3957,
          latitude: 37.7619,
          detail: 'Home',
          cycle: 127,
          radius: 300,
          is_send_alarm_email: false,
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
          lnglat: [-122.3957, 37.7619],
          isShowGeoMore: false,
        },
        {
          id: '652f42853c15fcb3eff2805e',
          name: 'Night Out',
          longitude: -122.4036,
          latitude: 37.7895,
          detail: 'Entertainment District',
          cycle: 62,
          radius: 500,
          is_send_alarm_email: false,
          isShowMore: false,
          loIndex: 2,
          color: {
            color: '#4080FF',
            backgroundColor: '#E8F3FF',
          },
          lnglat: [-122.4036, 37.7895],
          isShowGeoMore: false,
        },
        {
          id: '652f42853c15fcb3eff2805f',
          name: 'Gaming Zone',
          longitude: -122.4527,
          latitude: 37.7683,
          detail: 'Arcade Center',
          cycle: 62,
          radius: 200,
          is_send_alarm_email: false,
          isShowMore: false,
          loIndex: 3,
          color: {
            color: '#23C343',
            backgroundColor: '#E8FFEA',
          },
          lnglat: [-122.4527, 37.7683],
          isShowGeoMore: false,
        },
        {
          id: '652f42863c15fcb3eff28060',
          name: 'Mall Safety',
          longitude: -122.3986,
          latitude: 37.7955,
          detail: 'Shopping Mall',
          cycle: 1,
          radius: 400,
          is_send_alarm_email: false,
          isShowMore: false,
          loIndex: 4,
          color: {
            color: '#F99057',
            backgroundColor: '#FFF3E8',
          },
          lnglat: [-122.3986, 37.7955],
          isShowGeoMore: false,
        },
        {
          id: '652f42863c15fcb3eff28061',
          name: 'City Nights',
          longitude: -122.4198,
          latitude: 37.7627,
          detail: 'Downtown Entertainment',
          cycle: 2,
          radius: 600,
          is_send_alarm_email: false,
          isShowMore: false,
          loIndex: 5,
          color: {
            color: '#8D4EDA',
            backgroundColor: '#F5E8FF',
          },
          lnglat: [-122.4198, 37.7627],
          isShowGeoMore: false,
        },
      ],
    },
  },
};

export const jsonCrossingEvent = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      events: [
        {
          geo_fencing_id: '652f42853c15fcb3eff2805d',
          date: 1694563500,
          type: 1,
          fencing_name: 'Study Time',
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805d',
          date: 1694294399,
          type: 2,
          fencing_name: 'Study Time',
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
        },
        {
          geo_fencing_id: '652f42863c15fcb3eff28060',
          date: 1694294399,
          type: 1,
          fencing_name: 'Mall Safety',
          isShowMore: false,
          loIndex: 4,
          color: {
            color: '#F99057',
            backgroundColor: '#FFF3E8',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805d',
          date: 1694195999,
          type: 1,
          fencing_name: 'Study Time',
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805e',
          date: 1694195999,
          type: 2,
          fencing_name: 'Night Out',
          isShowMore: false,
          loIndex: 2,
          color: {
            color: '#4080FF',
            backgroundColor: '#E8F3FF',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805d',
          date: 1694195100,
          type: 2,
          fencing_name: 'Study Time',
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805e',
          date: 1694195100,
          type: 1,
          fencing_name: 'Night Out',
          isShowMore: false,
          loIndex: 2,
          color: {
            color: '#4080FF',
            backgroundColor: '#E8F3FF',
          },
        },
        {
          geo_fencing_id: '652f42853c15fcb3eff2805d',
          date: 1694178900,
          type: 1,
          fencing_name: 'Study Time',
          isShowMore: false,
          loIndex: 1,
          color: {
            color: '#F76560',
            backgroundColor: '#FFECE8',
          },
        },
      ],
      update_time: 1694563500,
    },
  },
};
