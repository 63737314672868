export const jsonDataLocation = {
  code: 200,
  message: 'OK',
  data: {
    locations: [
      {
        longitude: -122.423,
        latitude: 37.827,
        detail: 'Alcatraz Island, CA',
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -77.0353,
        latitude: 38.8895,
        detail: 'National Mall, DC',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -73.9683,
        latitude: 40.7851,
        detail: 'Central Park, NY',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -87.6233,
        latitude: 41.8837,
        detail: 'Millennium Park, IL',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -105.2057,
        latitude: 39.6654,
        detail: 'Red Rocks Amphitheatre, CO',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.3257,
        latitude: 34.1018,
        detail: 'Hollywood Walk of Fame, CA',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3992,
        latitude: 37.7957,
        detail: '4 Maritime Plaza',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3958,
        latitude: 37.7608,
        detail: 'Daniel Webster Elementary School',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4029,
        latitude: 37.7888,
        detail: "Mechanics' Institute",
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3892,
        latitude: 37.7787,
        detail: 'Oracle Park',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
    ],
    update_time: 1697596038,
  },
};

export const jsonDataLocation2 = {
  code: 200,
  message: 'OK',
  data: {
    locations: [
      {
        longitude: -122.423,
        latitude: 37.827,
        detail: 'Alcatraz Island, CA',
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -77.0353,
        latitude: 38.8895,
        detail: 'National Mall, DC',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -73.9683,
        latitude: 40.7851,
        detail: 'Central Park, NY',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -87.6233,
        latitude: 41.8837,
        detail: 'Millennium Park, IL',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -105.2057,
        latitude: 39.6654,
        detail: 'Red Rocks Amphitheatre, CO',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.3257,
        latitude: 34.1018,
        detail: 'Hollywood Walk of Fame, CA',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3992,
        latitude: 37.7957,
        detail: '4 Maritime Plaza',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3958,
        latitude: 37.7608,
        detail: 'Daniel Webster Elementary School',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4029,
        latitude: 37.7888,
        detail: "Mechanics' Institute",
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3892,
        latitude: 37.7787,
        detail: 'Oracle Park',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
    ],
  },
};

export const jsonDataLocation3 = {
  code: 200,
  message: 'OK',
  data: {
    locations: [
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3992,
        latitude: 37.7957,
        detail: '4 Maritime Plaza',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3958,
        latitude: 37.7608,
        detail: 'Daniel Webster Elementary School',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4029,
        latitude: 37.7888,
        detail: "Mechanics' Institute",
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.396,
        latitude: 37.7626,
        detail: "Hazel's Kitchen",
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.3892,
        latitude: 37.7787,
        detail: 'Oracle Park',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
    ],
    update_time: 1697597216,
  },
};

export const jsonDataLocation4 = {
  code: 200,
  message: 'OK',
  data: {
    locations: [
      {
        longitude: -122.423,
        latitude: 37.827,
        detail: 'Alcatraz Island, CA',
        date: 1694563500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -77.0353,
        latitude: 38.8895,
        detail: 'National Mall, DC',
        date: 1694546999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -73.9683,
        latitude: 40.7851,
        detail: 'Central Park, NY',
        date: 1694519999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -87.6233,
        latitude: 41.8837,
        detail: 'Millennium Park, IL',
        date: 1694443500,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -105.2057,
        latitude: 39.6654,
        detail: 'Red Rocks Amphitheatre, CO',
        date: 1694369400,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.3257,
        latitude: 34.1018,
        detail: 'Hollywood Walk of Fame, CA',
        date: 1694294399,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 37.3821,
        detail: 'Silicon Valley, CA',
        date: 1694195999,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -117.919,
        latitude: 33.8121,
        detail: 'Disneyland, CA',
        date: 1694195100,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -122.4783,
        latitude: 37.8199,
        detail: 'Golden Gate Bridge, CA',
        date: 1694178900,
        is_virtual: false,
        timezone_offset: 0,
      },
      {
        longitude: -118.4961,
        latitude: 34.0094,
        detail: 'Santa Monica Pier, CA',
        date: 1694114999,
        is_virtual: false,
        timezone_offset: 0,
      },
    ],
    update_time: 1697597216,
  },
};
